import {
  checkToken,
  encrypt, exception,
} from '@/utils/core';

import { Encrypt } from '@/interfaces/core';

export async function launchGame(gameData: any) {
  try {
    const response = await fetch('/api/casino/games/play', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ gameData }),
      credentials: 'include',
    });
    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.message || 'Failed to launch game';

      if (response.status === 401) {
        return { message: 'Necsitas iniciar sesion para jugar', login: true };
      }

      throw new Error(errorMessage);
    }

    const data = await response.json();
    return data;
  } catch (error: any) {
    await exception(error, { route: '/features/casino/services/index.ts: launchGame', method: 'POST', req: null });
    throw new Error(error.message || 'An error occurred while launching the game');
  }
}

export async function updateFavoriteGames(favorites: string[]) {
  try {
    const favoritesGames: Encrypt | string = await encrypt(JSON.stringify({ favorites }));

    const token = await checkToken(JSON.stringify({ favoritesGames }));

    if (!token) {
      return { success: false };
    }

    const res = await fetch('/api/casino/games/favorites', {
      method: 'PATCH',
      body: JSON.stringify({ favoritesGames }),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await res.json();
    return data.favoriteGames;
  } catch (error) {
    await exception(error, { route: '/utils/core.ts: updateFavoriteGames', method: 'DB', req: favorites });
    return { success: false };
  }
}
