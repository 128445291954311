'use client';

import { useEffect, useState } from 'react';

import Image from 'next/image';

import Link from 'next/link';

import SlotCounter from 'react-slot-counter';

import { Card, CardContent } from '@/components/ui/card';

export default function JackpotItem() {
  const [jackpotAmount, setJackpotAmount] = useState<string>('0');
  const slug = process.env.NEXT_PUBLIC_JACKPOT_ID ?? '';

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (slug) {
      const fetchJackpot = async () => {
        try {
          const response = await fetch(`/api/integrations/casino/jackpots?id=${slug}`, { cache: 'no-store' });
          const data = await response.json();
          setJackpotAmount(data.total_prize_pool.amount.toString());
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching jackpot:', error);
        }
      };

      fetchJackpot();

      const incrementJackpot = () => {
        setJackpotAmount((prevAmount) => {
          const newAmount = (parseFloat(prevAmount) + Math.random() * 9 + 1).toFixed(2);
          return newAmount.toString();
        });
      };

      const incrementIntervalId = setInterval(incrementJackpot, 10000);

      return () => {
        clearInterval(incrementIntervalId);
      };
    }
  }, [slug]);

  return (
    <Card className="relative w-full h-36 md:h-full overflow-hidden rounded-2xl border-0 transition-transform duration-300 hover:scale-95 hover:cursor-pointer">
      <Link prefetch={false} href="/jackpots" className="w-full h-full">
        <CardContent className="relative h-full z-10 p-0 flex flex-col justify-around items-center bg-gradient-to-t from-black/80 to-transparent">
          <Image
            src="/img/jackpots/jackpot-widget.webp"
            alt="jackpot"
            width={1600}
            height={400}
            className="w-full h-full transition-transform duration-300 hover:scale-105"
            priority
          />
          <div className="flex flex-col items-center justify-center space-y-1 absolute">
            <div className="text-[#017901] md:text-3xl text-xl drop-shadow-[0_1px_1px_rgba(0,0,0,0.8)]">
              {' '}
              <span className="font-extrabold">Jackpot </span>
              {' '}
              total
            </div>
            <div className="flex items-end gap-3 md:text-7xl text-4xl">
              <SlotCounter
                startValue="0"
                value={jackpotAmount}
                autoAnimationStart
                valueClassName="font-extrabold bg-gradient-to-l from-amber-600 via-yellow-400 to-amber-600 text-transparent bg-clip-text bg-[length:200%_auto] animate-gradient drop-shadow-[0_1px_1px_rgba(0,0,0,0.8)]"
                charClassName="font-extrabold bg-gradient-to-l from-amber-600 via-yellow-400 to-amber-600 text-transparent bg-clip-text bg-[length:200%_auto] animate-gradient drop-shadow-[0_1px_1px_rgba(0,0,0,0.8)]"
                numberClassName="font-extrabold bg-gradient-to-l from-amber-600 via-yellow-400 to-amber-600 text-transparent bg-clip-text bg-[length:200%_auto] animate-gradient drop-shadow-[0_1px_1px_rgba(0,0,0,0.8)]"
                separatorClassName="font-extrabold bg-gradient-to-l from-amber-600 via-yellow-400 to-amber-600 text-transparent bg-clip-text bg-[length:200%_auto] animate-gradient drop-shadow-[0_1px_1px_rgba(0,0,0,0.8)]"
              />
              <span className="font-extrabold bg-gradient-to-l from-amber-600 via-yellow-400 to-amber-600 text-transparent bg-clip-text bg-[length:200%_auto] animate-gradient drop-shadow-[0_1px_1px_rgba(0,0,0,0.8)]">
                HNL
              </span>
            </div>
          </div>
        </CardContent>
      </Link>
    </Card>
  );
}
