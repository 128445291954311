/* eslint-disable max-lines-per-function */
/* eslint-disable no-console */

'use client';

import React, {
  useEffect,
  useState,
  useRef,
  MutableRefObject,
  useReducer,
  memo,
  Suspense,
} from 'react';

// import dynamic from 'next/dynamic';
import Link from 'next/link';

import { useSearchParams } from 'next/navigation';
import Script from 'next/script';

// import { IoHeart } from 'react-icons/io5';

import Intercom from '@intercom/messenger-js-sdk';
import { useSession } from 'next-auth/react';

import { cancelUnlimitTxn, fetchConfirmationPayphone } from '@/features/deposit/services';
import Description from '@/features/Home/components/Description';
import { messages } from '@/localization';

import { useAuthStore } from '@/stores/userStore';
import { userUIStore } from '@/stores/userUIStore';

// import { getDatGames } from '@/utils/core';

import { decrypt, getDatGames } from '@/utils/core';

import { CarouselData, Promotion } from '@/interfaces/core';

import BannerCarousel from '../banner/BannerCarousel';

import JackpotItem from '../banner/jackpots/JackpotItem';
import LeagueSlider from '../banner/LeagueSlider';
import SWSmoothCarousel from '../banner/SWSmoothCarousel';
import TournamentsCarousel from '../banner/tournaments/TournamentsCarousel';
import LoadingSpinner from '../common/LoadingSpinner';

import { Skeleton } from '../ui/skeleton';
import { SkeletonBanner } from '../ui/SkeletonBanner';
import { useToast } from '../ui/use-toast';

interface HomeTabProps {
  initialBanners: any[];
  initialCategories: CarouselData[];
  initialTournaments: Promotion[];
}

const HomeTab: React.FC<HomeTabProps> = ({ initialBanners, initialCategories, initialTournaments }) => {
  const [categories, setCategories] = useState<CarouselData[]>(initialCategories);

  interface State {
    categories: CarouselData[];
    isFavoritesLoaded: boolean;
    isLoading: boolean;
  }
  const initialState: State = {
    categories: [],
    isFavoritesLoaded: false,
    isLoading: false,
  };

  type Action =
    | { type: 'SET_CATEGORIES'; payload: CarouselData[] }
    | { type: 'SET_LOADING'; payload: boolean }
    | { type: 'SET_FAVORITES_LOADED'; payload: boolean };

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case 'SET_CATEGORIES':
        return { ...state, categories: action.payload };
      case 'SET_LOADING':
        return { ...state, isLoading: action.payload };
      case 'SET_FAVORITES_LOADED':
        return { ...state, isFavoritesLoaded: action.payload };
      default:
        return state;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false);
  const [isWidgetReady, setIsWidgetReady] = useState<boolean>(false);
  const [isxtremepushSet, setIsxtremepushSet] = useState<boolean>(false);
  const widgetRef = useRef<MutableRefObject<any>>(null);
  const { toast } = useToast();
  const scriptUrl = process.env.NEXT_PUBLIC_SCRIPT_URL as string;
  const integration = process.env.NEXT_PUBLIC_ALTENAR_INTEGRATION as string;
  const { data: session } = useSession();
  const { account } = useAuthStore();
  const { isSidebarOpen } = userUIStore();
  const searchParams = useSearchParams();
  const id = searchParams.get('id');
  const TxnId = searchParams.get('clientTransactionId');
  const cont = searchParams.get('cont');
  const emailVerification = searchParams.get('emailVerified');
  const unlimit = searchParams.get('deposit');
  const unlimitGateway = searchParams.get('gateway');
  const unlimitTxn = searchParams.get('txn');
  const isIntercom = searchParams.get('chat');

  const urlBonusParam = searchParams.get(process.env.NEXT_PUBLIC_URL_VAR_BONUS_PARAM!);

  const filterCategories = (cat: CarouselData[], headline: string) => {
    return cat.filter((category: CarouselData) => { return category.headline !== headline; });
  };

  // const updateCategories = (prevCategories: CarouselData[], favoriteCategory: CarouselData) => {
  //   const newCategories = filterCategories(prevCategories, 'Favoritos');
  //   newCategories.unshift(favoriteCategory);
  //   return newCategories;
  // };

  useEffect(() => {
    if (isIntercom === 'true') {
      if (account) {
        Intercom({
          app_id: 'l2erqbk4',
          user_id: account.topup_id,
          name: account.name,
          email: account.email,
          company: 'Hondubet',
          created_at: account.createdAt ? Math.floor(new Date(account.createdAt).getTime() / 1000) : undefined,
          hide_default_launcher: true,
        });
      } else {
      // Unauthenticated user
        Intercom({
          app_id: 'l2erqbk4',
          company: 'Hondubet',
          hide_default_launcher: true,
        });
      }
      if (typeof window !== 'undefined' && window.Intercom) {
        window.Intercom('show'); // Opens the Intercom Messenger
      }
    }
  }, [isIntercom, account]);

  useEffect(() => {
    const cancelUnlimit = async () => {
      if (unlimit === 'cancel' && unlimitGateway === 'unlimit' && unlimitTxn) {
        if (session?.user?.data && session?.user?.tag) {
          const userId = await decrypt(session?.user?.data, session?.user?.tag);
          const response = await cancelUnlimitTxn({
            id: Number(userId),
            deposit_id: unlimitTxn,
          });
          if (response.processed) {
            console.log(response.message);
          } else {
            console.log('Error al cancelar la transacción');
          }
        }
      }
    };
    cancelUnlimit();
  }, [unlimit, unlimitGateway, unlimitTxn, session]);

  useEffect(() => {
    const messagesLimits: Record<number, string> = {
      1: messages.casinoLimit,
      2: messages.sportsLimit,
      3: messages.withdrawalLimit,
      4: messages.depositLimit,
    };
    const textVerified: Record<number, string> = {
      1: 'Lo sentimos, El tiempo de verificación de tu correo electrónico ha expirado. Por favor, intenta nuevamente más tarde o contacta a soporte.',
      2: 'Tu correo ha sido verificado exitosamente. Si no has hecho tu primer depósito, hazlo ahora para que disfrutes de toda la emoción!',
      3: 'Lo sentimos, no pudimos verificar tu correo electrónico. Por favor, intenta nuevamente más tarde o contacta a soporte.',
    };

    if (cont && messagesLimits[Number(cont)]) {
      toast({
        title: 'Informacion',
        description: messagesLimits[Number(cont)],
      });
    }

    if (emailVerification) {
      toast({
        title: 'Informacion',
        description: textVerified[Number(emailVerification)],
        variant: emailVerification === '2' ? 'default' : 'destructive',
      });
    }

    if ((!urlBonusParam || urlBonusParam !== process.env.NEXT_PUBLIC_URL_BONUS_PARAM_VALUE) && !emailVerification) {
      window.history.replaceState({}, '', '/');
    }
  }, [cont, urlBonusParam, toast, emailVerification]);

  useEffect(() => {
    const updateCategories = (prevCategories: CarouselData[], favoriteCategory: CarouselData) => {
      const newCategories = filterCategories(prevCategories, 'Favoritos');
      newCategories.unshift(favoriteCategory);
      return newCategories;
    };

    const fetchFavorites = async () => {
      if (account.favoriteGames && account.favoriteGames.length > 0) {
        const favoritesData = await getDatGames(account.favoriteGames, 'home');
        const favoriteCategory: CarouselData = {
          headline: 'Favoritos',
          items: favoritesData.gameData,
          url: '/favoritos',
          icon: '',
        };

        setCategories((prevCategories: CarouselData[]) => { return updateCategories(prevCategories, favoriteCategory); });
      }
    };

    fetchFavorites();
  }, [account.favoriteGames]);

  useEffect(() => {
    if ((window as any).xtremepush) {
      if (!isxtremepushSet) {
        (window as any).xtremepush('event', 'pageview_home');
        setIsxtremepushSet(true);
      }
    }
  }, [isxtremepushSet, setIsxtremepushSet]);

  useEffect(() => {
    const initAltenar = async () => {
      const initConfig = {
        integration,
        culture: 'es-ES',
        themeName: 'dark',
      };
      (window as any).altenarWSDK?.init(initConfig);

      (widgetRef as any).current = (window as any).altenarWSDK.addWidget({
        widget: 'WTopEventsCarousel',
        props: {
          onEventSelect: (event: any) => {
            window.location.href = `/deportes?event=${event.id}`;
          },
          onOddSelect: (odd: any) => {
            window.location.href = `/deportes?event=${odd.eventId}`;
          },
          backgroundOverride: {
            sport: {
              66: 'https://cdn.sanity.io/images/mavwf57a/production/413b526a262b3e12b000b3671d779c58362de3e1-400x141.webp',
              67: 'https://cdn.sanity.io/images/mavwf57a/production/0cb2696d8a088415ee2cdb14e152e9247db47b77-1792x1024.webp',
              68: 'https://cdn.sanity.io/images/mavwf57a/production/8c8e1b0d1e029261a620e0890aee4897eeb87ba2-1792x1024.webp',
              76: 'https://cdn.sanity.io/images/mavwf57a/production/2b74598240c46346d5717df0446ad3a50a3fd75b-1792x1024.webp',
            },
            championship: {
              2936: 'https://cdn.sanity.io/images/mavwf57a/production/edf7cba173a1bb5906203bd93cecda5265280ac1-400x141.jpg',
              2937: 'https://cdn.sanity.io/images/mavwf57a/production/61d1c9bebb8fb9c7b382a46d93d8ea3e1a9ad8b7-400x141.jpg',
              2941: 'https://cdn.sanity.io/images/mavwf57a/production/a0084609818ce53445eedd8fb7774888ccff48c7-400x141.jpg',
              2942: 'https://cdn.sanity.io/images/mavwf57a/production/d0f5fb9f7cbfb026c1e9c1d0f24d02e4045ce80e-400x141.jpg',
              2943: 'https://cdn.sanity.io/images/mavwf57a/production/f1dcc4a822076cc4d4e527bd8d925465710c07cc-400x141.jpg',
              2950: 'https://cdn.sanity.io/images/mavwf57a/production/19ede187303d64c74fb296289f5422135c364d63-400x141.jpg',
              2973: 'https://cdn.sanity.io/images/mavwf57a/production/c000d5a0d9db987dd27ff13ede0ed21170225f01-400x141.jpg',
              3070: 'https://cdn.sanity.io/images/mavwf57a/production/61a6cd9e7b53c0cd17eeac26a5770088cef80ec5-400x141.jpg',
              3075: 'https://cdn.sanity.io/images/mavwf57a/production/464465ead8676fa95633752fa02526939604b8ed-400x141.jpg',
              3102: 'https://cdn.sanity.io/images/mavwf57a/production/493a3c0708b76d4768b7b5093128e03535db17bd-400x141.jpg',
              3112: 'https://cdn.sanity.io/images/mavwf57a/production/97eaa79afd8cc4539f3ebd5f289bb86e0e048007-400x141.jpg',
              3111: 'https://cdn.sanity.io/images/mavwf57a/production/53d662d9d162d9cfb0ceec84879bacc5a591fd5c-400x141.jpg',
              3152: 'https://cdn.sanity.io/images/mavwf57a/production/4feda998f26618f731545bb34417214addb02847-400x141.jpg',
              3679: 'https://cdn.sanity.io/images/mavwf57a/production/fc81231a747680dd0cabb4e502184aecf6ae7799-400x141.jpg',
              3868: 'https://cdn.sanity.io/images/mavwf57a/production/10aeabcde190e9f0e0ddb341fceac7c64b99b97c-400x141.jpg',
              4626: 'https://cdn.sanity.io/images/mavwf57a/production/1064504f81cd8c67c84899a3a15182b128f2a80e-400x141.jpg',
              39792: 'https://cdn.sanity.io/images/mavwf57a/production/08055f57924bf2c985749a8fcf0863b2bc23c448-400x141.jpg',
              10009: 'https://cdn.sanity.io/images/mavwf57a/production/feb9f1d000917bc0156150b3c2a886731bfcbcb1-400x141.jpg',
              16809: 'https://cdn.sanity.io/images/mavwf57a/production/46895656aab2d7d81a6010da97802b6f1ddb08ab-400x141.jpg',
              3685: 'https://cdn.sanity.io/images/mavwf57a/production/b0ad17f6532a9445022289c9e822433cb7006b3a-400x141.png',
              17135:
                'https://cdn.sanity.io/images/mavwf57a/production/14305ec6ee0f818e5a7079a3620891dbc0dba422-400x141.png',
              32729:
                'https://cdn.sanity.io/images/mavwf57a/production/14305ec6ee0f818e5a7079a3620891dbc0dba422-400x141.png',
            },
          },
          scrollerControls: 3,
          showScorePreview: true,
          variants: {
            BannerEventBox: 4,
          },
        },
        tokens: {
          OddBox: {
            borderWidth: 1,
            borderColor: '#93346a00',
            background: '#0164abba',
            oddColor: '#95fb3fe6',
            labelColor: '#95fb3fe6',
          },
          OddBoxSelected: {
            background: '#109fe9c7',
            oddColor: '#95fb3fe6',
            labelColor: '#95fb3fe6',
          },
        },
        container: document.getElementById('topscores'),
      });
      setIsWidgetReady(true);
    };
    if (isScriptLoaded) {
      // initAltenar();
      setTimeout(() => {
        initAltenar();
      }, 1000);
    }

    return () => {
      if ((widgetRef as any).current) {
        (widgetRef as any).current.remove();
      }
    };
  }, [integration, isScriptLoaded]);

  useEffect(() => {
    const checkConfirmation = async () => {
      if (id && TxnId) {
        dispatch({ type: 'SET_LOADING', payload: true });
        const data = {
          id: Number(id),
          clientTxId: TxnId,
        };
        try {
          const response = await fetchConfirmationPayphone(data);
          if (response.processed) {
            dispatch({ type: 'SET_LOADING', payload: false });
            toast({
              title: 'Depósito Creado',
              description: 'El depósito se ha creado exitosamente',
              variant: 'default',
              className: 'bg-sivarbet-secondary',
            });
          } else {
            console.log(
              'Error al confirmar el depósito / checkConfirmation method / response.processed',
            );
            dispatch({ type: 'SET_LOADING', payload: false });
            toast({
              title: 'Error',
              description: 'Error al confirmar el depósito.',
              variant: 'destructive',
            });
          }
        } catch (error) {
          console.log(
            'Error al confirmar el depósito / checkConfirmation method: ',
            error,
          );
          dispatch({ type: 'SET_LOADING', payload: false });
          toast({
            title: 'Error',
            description: 'Error al confirmar el depósito.',
            variant: 'destructive',
          });
        } finally {
          const url = new URL(window.location.href);
          url.search = '';
          window.history.replaceState({}, '', url.toString());
        }
      }
    };

    checkConfirmation();
  }, [id, TxnId, toast]);

  return (
    <>
      <Script
        src={`${scriptUrl}/altenarWSDK.js`}
        strategy="lazyOnload"
        onReady={() => {
          setIsScriptLoaded(true);
        }}
      />
      {/* {state.isLoading && <LoadingSpinner />} */}
      <div className={`${!isSidebarOpen ? 'pl-72' : 'pl-20'} w-full pt-20 max-lg:pl-0 max-lg:pt-[4.2rem]`}>

        <div
          className="text-sivarbet-text flex flex-col items-stretch w-full"
          id="mainTab"
          role="tabpanel"
          tabIndex={0}
        >
          <Suspense fallback={<SkeletonBanner />}>
            <BannerCarousel banners={initialBanners} />
          </Suspense>
          <div className="mt-3">
            <Link href="/deportes" passHref className="flex items-center gap-2">
              <span className="icon icon-calendar text-l" />
              <h2 className="text-base sm:text-sm md:text-lg lg:text-xl">
                Eventos Populares
              </h2>
            </Link>
            <div id="topscores" className="mt-6 max-lg:mt-1" />
            {!isWidgetReady && (
              <div className="mt-6 max-lg:mt-1 flex gap-2">
                <Skeleton className="h-[134.5px] w-full sm:w-[400px] rounded-md bg-sivarbet-border" />
                <div className="hidden sm:flex gap-2">
                  <Skeleton className="h-[134.5px] w-[400px] rounded-md bg-sivarbet-border" />
                  <Skeleton className="h-[134.5px] w-[400px] rounded-md bg-sivarbet-border" />
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col items-stretch mt-5 max-lg:mt-2 max-md:mt-2">
            <LeagueSlider />
          </div>
          <div className="w-full mt-5">
            <Suspense fallback={<SkeletonBanner />}>
              <TournamentsCarousel initialTournaments={initialTournaments} />
            </Suspense>
          </div>
          <div className="w-full mt-5">
            <Link prefetch={false} href="/jackpots" passHref className="flex items-baseline gap-2">
              <span className="icon icon-slot text-lg" />
              <h2 className="text-base sm:text-sm md:text-lg lg:text-xl font-medium text-sivarbet-text pb-3">
                Jackpot
              </h2>
            </Link>
            <Suspense fallback={<SkeletonBanner />}>
              <JackpotItem />
            </Suspense>
          </div>
          <div className="flex flex-col items-stretch w-full pt-5 max-lg:pt-0 max-md:pt-0">
            <Suspense fallback={<LoadingSpinner />}>
              <SWSmoothCarousel data={categories || []} />
            </Suspense>
          </div>
        </div>
        <Description />
      </div>
    </>
  );
};

export default memo(HomeTab);
