'use client';

/* eslint-disable max-lines-per-function */

import Image from 'next/image';
import Link from 'next/link';

import Copyright from '@/features/footer/Copyright';
import FooterLinks from '@/features/footer/FooterLinks';
import Sponsors from '@/features/footer/Sponsors';

import { Separator } from '../ui/separator';

const Footer = () => {
  return (
    <footer className="bg-gray flex flex-col h-full py-8 px-4 sm:px-8 border-t-2 border-sivarbet-secondary-bg lg:pl-28">

      <FooterLinks />
      <Separator className="bg-sivarbet-border my-4" />
      <Sponsors />
      <Separator className="bg-sivarbet-border my-4" />
      <Copyright />
      <div className="flex flex-wrap justify-center items-center gap-4 mb-10">
        <Link href="/landing/app" prefetch={false} className="p-1" style={{ width: '150px', marginTop: '10px' }}>
          <Image src="/img/footer/android.svg" alt="Android App" width={150} height={50} />
        </Link>
        <Link href="/landing/app" prefetch={false} className="p-1" style={{ width: '150px', marginTop: '10px' }}>
          <Image src="/img/footer/apple.svg" alt="Apple App" width={150} height={50} />
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
