import { NextResponse } from 'next/server';

import Crypto from 'crypto';

import {
  checkToken, encrypt, exception,
  findUserById,
} from '@/utils/core';
import { prisma } from '@/utils/prisma';

export async function fetchCoinRates() {
  try {
    const response = await fetch('/api/deposit/coinpayments/getRates', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
    }

    return await response.json();
  } catch (error) {
    return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
  }
}

export async function createCallbackAddress(data: any) {
  try {
    const response = await fetch('/api/deposit/coinpayments/getCallbackAddress', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Error with the network please try again later');
    }

    return await response.json();
  } catch (error) {
    await exception(error, { route: '/features/deposit/services/index.ts: createCallbackAddress', method: 'POST', req: data });
    return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
  }
}

export async function getNicoCallback(data: any) {
  try {
    const response = await fetch('/api/deposit/nico', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return await response.json();
  } catch (error) {
    await exception(error, { route: '/features/deposit/services/index.ts: getNicoCallback', method: 'POST', req: data });
    return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
  }
}

export async function getPayPhoneCallback(data: any) {
  try {
    const token = await checkToken(JSON.stringify(data));
    const encryptedData = await encrypt(JSON.stringify(data));

    if (!token) {
      return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
    }

    const response = await fetch('/api/deposit/payphone', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(encryptedData),
    });
    const res = await response.json();
    return res;
  } catch (error) {
    await exception(error, { route: '/features/deposit/services/index.ts: getPayPhoneCallback', method: 'POST', req: data });
    return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
  }
}

export async function getDirectaCallback(data: any) {
  try {
    const response = await fetch('/api/deposit/directa', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const { response: res } = await response.json();
    return res;
  } catch (error) {
    await exception(error, { route: '/features/deposit/services/index.ts: getDirectaCallback', method: 'POST', req: data });
    return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
  }
}

export async function getUnlimitCallback(data: any) {
  try {
    const response = await fetch('/api/deposit/unlimit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (error) {
    await exception(error, { route: '/features/deposit/services/index.ts: getUnlimitCallback', method: 'POST', req: data });
    return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
  }
}

export async function fetchConfirmationPayphone(data: any) {
  // eslint-disable-next-line no-console
  console.log('🚀 ~ fetchConfirmationPayphone ~ data:', data);
  try {
    const token = await checkToken(JSON.stringify(data));
    const encryptedData = await encrypt(JSON.stringify(data));
    // eslint-disable-next-line no-console
    console.log('🚀 ~ fetchConfirmationPayphone ~ encryptedData:', encryptedData);

    if (!token) {
      return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
    }

    const response = await fetch('/api/deposit/webhook/payphone', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(encryptedData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      // eslint-disable-next-line no-console
      console.log('error fetchConfirmationPayphone', errorData.error);
      throw new Error(errorData.error || 'Error with the network please try again later');
    }

    return await response.json();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error fetchConfirmationPayphone', error);
    await exception(error, { route: '/features/deposit/services/index.ts: fetchConfirmationPayphone', method: 'POST', req: data });
    return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
  }
}
/* eslint-disable no-console */
export async function fetchCreateBonus(userId: number, deposit: number, pdvAndTigo: boolean = false) {
  try {
    // ================== Create Bonus promisse ==================
    const response = await fetch(`${process.env.NEXT_PUBLIC_RESPONSE_URL}/api/integrations/bonusService`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, deposit, pdvAndTigo }),
    });

    console.log('response========> Bonus', response);
    console.log('response.ok========> Bonus', response.ok);

    if (!response.ok) {
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error al crear el bono:', error);
    return NextResponse.json({ error: 'External request failed try again later' }, { status: 500 });
  }
}

export async function findUserInAltenar(user_id: number) {
  try {
    const bonus = await prisma.users.findFirst({
      where: {
        id: user_id,
        status: true,
        altenar: {
          not: null,
        },
      },
      select: {
        altenar: true,
      },
    });
    return !!bonus?.altenar;
  } catch (error) {
    await exception(error, {
      route: '/features/deposit/utils/index.ts: findBonusByDeposit',
      method: 'GET',
      req: { user_id },
    });
    return false;
  }
}

export async function fetchCreateUserBonusByDeposit(data:any) {
  try {
    // const isRegistrer = await findUserInAltenar(data.id_user);
    // if (!isRegistrer) {
    //   const
    // }
    const responseData = await findUserInAltenar(data.id_user);
    return responseData;
  } catch (error) {
    return NextResponse.json({ error: 'External request failed try again later' }, { status: 500 });
  }
}

export const ginihAuth = async (data: any) => {
  try {
    const paramsArray:Array<any> = Object.values(data);
    const paramsArrayCopy = [...paramsArray];
    const privateKey = process.env.NEXT_PUBLIC_GINIH_PRIVATE_KEY || '';
    paramsArrayCopy.push(privateKey);
    const dataHash = paramsArrayCopy.join('^');
    const signature = Crypto.createHash('sha256').update(dataHash).digest('hex');

    const tokenRes = await fetch('/api/deposit/ginih/auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ h_private_key: privateKey }),
    });

    const token = await tokenRes.json();

    if (!token.data) {
      return { token: '', signature: '' };
    }

    return { token: token.data, signature };
  } catch (error) {
    return { token: '', signature: '' };
  }
};

export const depositGinih = async (data: any) => {
  try {
    const { token, signature } = await ginihAuth(data);

    if (!token || !signature) {
      return { data: {}, success: false };
    }

    const response = await fetch('/api/deposit/ginih/deposit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ip: '186.97.212.162',
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...data, h_signature: signature }),
    });

    const res = await response.json();
    console.log('🚀 ~ depositGinih ~ res:', res);
    if (res.data.status === 'success') {
      return { data: res, success: true };
    }
    return { data: {}, success: false };
  } catch (error) {
    return { data: {}, success: false };
  }
};

export const cancelUnlimitTxn = async ({ id, deposit_id }: { id: number; deposit_id: string }) => {
  try {
    const response = await fetch('/api/deposit/unlimit/cancel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, deposit_id }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getGateways = async () => {
  try {
    const response = await fetch('/api/deposit/gateways', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return null;
  }
};

export const createCustomer = async (id: number) => {
  try {
    const user = await findUserById(id);

    const response = await fetch(`${process.env.EUKA_API_URL}/customers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.EUKA_API_KEY ?? '',
      },
      body: JSON.stringify({
        email: user.email,
        firstName: user.name,
        lastName: user.last_name,
      }),
    });
    const data = await response.json();

    console.log('🚀 ~ createCustomer ~ data:', data);
    if (data.statusCode === 400) {
      return {
        data: null,
        success: false,
      };
    }

    return {
      data,
      success: true,
    };
  } catch (error) {
    await exception(error, { route: '/features/deposit/services/index.ts: createCustomer', method: 'POST', req: id });
    return {
      data: null,
      success: false,
    };
  }
};

export const getCustomer = async (id: number) => {
  try {
    const user = await findUserById(id);
    const response = await fetch(`${process.env.EUKA_API_URL}/customers/?limit=1&email=${user.email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.EUKA_API_KEY ?? '',
      },
    });
    const { data } = await response.json();

    if (data.statusCode === 400) {
      return {
        data: null,
        success: false,
      };
    }

    return {
      data: data[0],
      success: true,
    };
  } catch (error) {
    await exception(error, { route: '/features/deposit/services/index.ts: getCustomer', method: 'GET', req: id });
    return {
      data: null,
      success: false,
    };
  }
};

export const createInvoiceEukapay = async (price: number, currencyId: number, customerCode: string) => {
  try {
    const response = await fetch(`${process.env.EUKA_API_URL}/invoices`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.EUKA_API_KEY ?? '',
      },
      body: JSON.stringify({
        price,
        currencyId,
        customerCode,
        redirectUri: 'https://hondubet.com',
      }),
    });
    const data = await response.json();

    if (data.statusCode === 400) {
      return {
        data: null,
        success: false,
      };
    }

    return {
      data,
      success: true,
    };
  } catch (error) {
    return {
      data: null,
      success: false,
    };
  }
};

export const getEukaUrl = async (data: any) => {
  try {
    const response = await fetch('/api/deposit/eukapay', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const res = await response.json();

    if (res.success === false || (res.data.statusCode && res.data.statusCode.toString().includes('40'))) {
      return {
        res,
        message: 'Error iniciando el pago',
        success: false,
      };
    }

    return {
      res,
      message: 'Pago iniciado correctamente',
      success: true,
    };
  } catch (error) {
    return {
      res: null,
      message: 'Error iniciando el pago',
      success: false,
    };
  }
};
