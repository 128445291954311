'use client';

import React, { useCallback, useEffect, useState } from 'react';

import Image from 'next/image';

import { useRouter } from 'next/navigation';

import Autoplay from 'embla-carousel-autoplay';

import Fade from 'embla-carousel-fade';
import { isMobile } from 'react-device-detect';

import urlFor from '@/sanity/urlFor';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel';

import { SkeletonBanner } from '@/components/ui/SkeletonBanner';

const BannerCarousel = ({ banners }: any) => {
  // const { toggleDepositSheet, toggleDepositModal, toggleLoginModal } = userUIStore();
  const [bannersSorted, setBannersSorted] = useState<any[]>([]);
  // const searchParams = useSearchParams();
  const router = useRouter();
  const handleBannerClick = useCallback((url: string) => {
    router.push(url);
  }, [router]);

  useEffect(() => {
    let bannersFiltered: any[] = [];
    if (isMobile) {
      bannersFiltered = banners.banners.filter((banner: any) => { return banner.isMobile; });
    } else {
      bannersFiltered = banners.banners.filter((banner: any) => { return !banner.isMobile; });
    }
    const sortedBanners = bannersFiltered.sort((a: any, b: any) => {
      if (a.orderRank && b.orderRank) {
        return a.orderRank.localeCompare(b.orderRank);
      }
      return 0; // No sorting if orderRank is missing
    });
    setBannersSorted(sortedBanners);
  }, [banners]);

  if (bannersSorted.length === 0) {
    return <SkeletonBanner />;
  }

  return (
    <div className="relative w-full">
      <Carousel
        className="h-full w-full max-lg:w-[100vw] rounded-lg"
        plugins={[Fade(), Autoplay({ delay: 8000 })]}
        opts={{
          align: 'start',
          containScroll: 'trimSnaps',
        }}
      >
        <CarouselContent className="w-full max-lg:m-0 max-lg:p-0">
          {bannersSorted.map((banner, index) => {
            const imageUrl = urlFor(banner.imageUrl).url();
            return (
              // eslint-disable-next-line no-underscore-dangle
              <CarouselItem key={banner._id} className="w-full  max-lg:m-0 max-lg:p-0">
                <div className="relative h-auto w-full rounded-lg overflow-hidden">
                  <div className="relative w-full h-full">
                    <Image
                      src={imageUrl}
                      alt="banner image"
                      width="1600"
                      height="400"
                      sizes="100vw"
                      className="h-auto w-full"
                      onClick={() => { return handleBannerClick(banner.urlRedirection); }}
                      priority={index === 0}
                      loading={index === 0 ? 'eager' : 'lazy'}
                    />
                  </div>
                </div>
              </CarouselItem>
            );
          })}
        </CarouselContent>
      </Carousel>
    </div>
  );
};

export default BannerCarousel;
