import React, { memo } from 'react';

import Link from 'next/link';

import { GiPodium } from 'react-icons/gi';

import { Promotion } from '@/features/Promotions/components/PromotionsContent';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';

import TournamentItem from './TournamentItem';

// type ContentData = {
//   tournaments: {
//     active: Promotion[]
//     upcoming: Promotion[]
//     archived: Promotion[]
//     slug: string
//   }
// };

const TournamentsCarousel = memo(({ initialTournaments }: { initialTournaments: Promotion[] }) => {
  const filterAndSortContent = (data: Promotion[]) => {
    const offset = 6 * 60 * 60 * 1000;
    const currentDate = new Date(new Date().getTime() - offset);

    const active = data.filter((item: Promotion) => {
      return (
        new Date(new Date(item.startDate).getTime() - offset) <= currentDate
        && new Date(new Date(item.endDate).getTime() - offset) >= currentDate
      );
    });

    const upcoming = data.filter((item: Promotion) => {
      return new Date(new Date(item.startDate).getTime() - offset) > currentDate;
    });

    const archived = data.filter((item: Promotion) => {
      return new Date(new Date(item.endDate).getTime() - offset) < currentDate;
    });

    const sortByOrderRank = (a: Promotion, b: Promotion) => {
      return a.orderRank.localeCompare(b.orderRank);
    };

    return {
      active: active.sort(sortByOrderRank),
      upcoming: upcoming.sort(sortByOrderRank),
      archived: archived.sort(sortByOrderRank),
      slug: '',
    };
  };

  const { active } = filterAndSortContent(initialTournaments);

  return (
    <div className="w-full space-y-4">

      <Carousel
        opts={{
          align: 'start',
          loop: true,
        }}
        className="w-full"
      >
        <div className="flex items-center justify-between">
          <Link prefetch={false} href="/torneos" passHref className="flex items-baseline gap-2">
            <GiPodium className="text-sivarbet-text text-xl" />
            <h2 className="text-base sm:text-sm md:text-lg lg:text-xl font-medium text-sivarbet-text pb-3">
              Torneos
            </h2>
          </Link>
          <div className="flex flex-row-reverse gap-3 pb-2">
            <CarouselNext className="static rounded-lg border-sivarbet-border flex items-center bg-transparent transform-none" />
            <CarouselPrevious className="static rounded-lg border-sivarbet-border flex items-center bg-transparent transform-none" />
          </div>
        </div>
        <CarouselContent className="-ml-4">
          {active?.map((item) => {
            return (
              <CarouselItem
                key={item.slug}
                className="pl-4 basis-full sm:basis-1/2 md:basis-1/3 hover:cursor-pointer hover:scale-95 transition-all duration-300 hover:rounded-2xl"
              >
                <Link prefetch={false} href={`/torneos/${item.slug}`}>

                  <TournamentItem
                    cmsTournament={item}
                    title={item.title}
                    prizePool={item.prizePool}
                    image={item.imageUrl}
                  />
                </Link>
              </CarouselItem>
            );
          })}
        </CarouselContent>
      </Carousel>
    </div>
  );
});

export default TournamentsCarousel;
